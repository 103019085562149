import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    window.addEventListener("scroll", this.mixBlendElement.bind(this))
  }

  disconnect() {
    window.removeEventListener("scroll", this.mixBlendElement.bind(this))
  }

  mixBlendElement() {
    if (window.scrollY <= 0) this.element.style.mixBlendMode = "initial"
    else this.element.style.mixBlendMode = "difference"
  }
}
