import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  show() {
    if (!(sessionStorage.getItem("hide_popup") == "yes")) {
      setTimeout(() => {
        this.element.classList.remove("opacity-0")
        this.element.classList.remove("pointer-events-none")
      }, 500)
    }
  }

  remove() {
    this.element.remove()
    sessionStorage.setItem("hide_popup", "yes")
  }
}
